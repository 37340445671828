import React, { useState, useEffect } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme, FormControl, InputLabel, Select, MenuItem, DialogContentText, IconButton, Paper, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Home from './Home';
import { SelectChangeEvent } from '@mui/material/Select';

interface Visa {
  id: string;
  id_pays: string;
  libelle_pays: string;
  titre: string;
  categorie: string;
  prixconsulat: string;
  prixintervention: string;
  url: string;
  informations: string;
  ordre: number;
  validite: string;
}

interface Pays {
  id: string;
  libelle: string;
}

const VisasCrud = () => {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const [visas, setVisas] = useState<Visa[]>([]);
  const [filteredVisas, setFilteredVisas] = useState<Visa[]>([]);
  const [open, setOpen] = useState(false);
  const [currentVisa, setCurrentVisa] = useState({ id: '', id_pays: '', titre: '', libelle_pays: '', prixconsulat: '', prixintervention: '', url: '', informations: '', ordre: 0, validite: ''  });
  const [isEdit, setIsEdit] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [pays, setPays] = useState<Pays[]>([]);
  const [searchTerm, setSearchTerm] = useState(''); // Ajout du champ de recherche

  useEffect(() => {
    fetchPays();
    fetchVisas();
  }, []);

  useEffect(() => {
    // Filtrer les visas en fonction du champ de recherche `searchTerm`
    const filtered = visas.filter(
      (visa) =>
        visa.libelle_pays.toLowerCase().includes(searchTerm.toLowerCase()) ||
        visa.titre.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredVisas(filtered);
  }, [searchTerm, visas]);

  const fetchVisas = async () => {
    const response = await fetch('https://nodejs-admin.visamonde.com/visas');
    const data = await response.json();
    setVisas(data);
    setFilteredVisas(data); // Initialisation de la liste filtrée
  };

  const fetchPays = async () => {
    const response = await fetch('https://nodejs-admin.visamonde.com/pays');
    const data = await response.json();
    setPays(data);
  };

  const handleOpen = (visa: Visa | null = null) => {
    if (visa) {
      setCurrentVisa(visa);
      setIsEdit(true);
    } else {
      setCurrentVisa({ id: '', id_pays: '', titre: '', libelle_pays: '', prixconsulat: '', prixintervention: '', url: '', informations: '', ordre: 0, validite:''  });
      setIsEdit(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const name = event.target.name;
    let value: any = event.target.value;
  
    setCurrentVisa(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const method = isEdit ? 'PUT' : 'POST';
    const endpoint = isEdit ? `https://nodejs-admin.visamonde.com/visas/${currentVisa.id}` : 'https://nodejs-admin.visamonde.com/visas';

    await fetch(endpoint, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(currentVisa),
    });

    fetchVisas();
    handleClose();
  };

  const handleOpenConfirm = (id: string) => {
    setOpenConfirm(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async () => {
    if (deleteId) {
      await fetch(`https://nodejs-admin.visamonde.com/visas/${deleteId}`, {
        method: 'DELETE',
      });
  
      fetchVisas();
      setDeleteId(null);
      setOpenConfirm(false);
    }
  };

  return (
    <>
      <Home />
      <Typography variant="h6" style={{ margin: '20px 0', marginLeft: '15px' }}>
        Nombre de Visas: {filteredVisas.length}
      </Typography>

      {/* Champ de recherche par Pays et Titre */}
      <TextField
        label="Rechercher par Pays ou Titre"
        variant="outlined"
        style={{ marginBottom: '20px', marginLeft: '20px', width: '50%' }}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

    <Button 
      variant="contained" 
      color="primary" 
      style={{ marginBottom: '20px', marginLeft: '20px' }}
      onClick={() => handleOpen(null)} // null pour indiquer la création d'un nouveau visa
    >
      Créer un visa
    </Button>


      <Paper sx={{ margin: 2, padding: 2 }}>
        <Grid container spacing={2}>
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12} sm={2}><b>Titre</b></Grid>
            <Grid item xs={6} sm={2}><b>Pays</b></Grid>
            <Grid item xs={6} sm={1}><b>Prix Consulat</b></Grid>
            <Grid item xs={6} sm={2}><b>Prix Intervention</b></Grid>
            <Grid item xs={6} sm={2}><b>Informations</b></Grid>
            <Grid item xs={6} sm={1}><b>Ordre</b></Grid>
            <Grid item xs={6} sm={1}><b>Validité</b></Grid>
            <Grid item xs={12} sm={1}><b>Actions</b></Grid>
          </Grid>

          {/* Données filtrées */}
          {filteredVisas.map((visa, index) => (
            <React.Fragment key={visa.id}>
              {index > 0 && <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }} />}
              <Grid container spacing={2} sx={{ '&:hover': { backgroundColor: 'action.hover' }, padding: 1 }}>
                <Grid item xs={12} sm={2}>{visa.titre}</Grid>
                <Grid item xs={6} sm={2}>{visa.libelle_pays}</Grid>
                <Grid item xs={6} sm={1}>{visa.prixconsulat}</Grid>
                <Grid item xs={6} sm={2}>{visa.prixintervention}</Grid>
                <Grid item xs={6} sm={2}>{visa.informations}</Grid>
                <Grid item xs={6} sm={1}>{visa.ordre}</Grid>
                <Grid item xs={6} sm={1}>{visa.validite}</Grid>
                <Grid item xs={12} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={() => handleOpen(visa)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleOpenConfirm(visa.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Paper>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{isEdit ? 'Modifier le visa' : 'Ajouter un visa'}</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" id="titre" name="titre" label="Titre" type="text" fullWidth variant="outlined" value={currentVisa.titre} onChange={handleChange} sx={{ mb: 2 }} />
          <FormControl fullWidth margin="dense" variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="id_pays-label">Pays</InputLabel>
            <Select
              labelId="id_pays-label"
              id="id_pays"
              name="id_pays"
              value={currentVisa.id_pays}
              onChange={handleChange}
              label="Pays"
            >
              {pays.map((pays) => (
                <MenuItem key={pays.id} value={pays.id}>{pays.libelle}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField margin="dense" id="prixconsulat" name="prixconsulat" label="Prix Consulat" type="number" fullWidth variant="outlined" value={currentVisa.prixconsulat} onChange={handleChange} sx={{ mb: 2 }} />
          <TextField margin="dense" id="prixintervention" name="prixintervention" label="Prix Intervention" type="number" fullWidth variant="outlined" value={currentVisa.prixintervention} onChange={handleChange} sx={{ mb: 2 }} />
          <TextField margin="dense" id="informations" name="informations" label="Informations" type="text" fullWidth variant="outlined" value={currentVisa.informations} onChange={handleChange} sx={{ mb: 2 }} />
          <TextField margin="dense" id="ordre" name="ordre" label="Ordre" type="number" fullWidth variant="outlined" value={currentVisa.ordre} onChange={handleChange} sx={{ mb: 2 }} />
          <TextField margin="dense" id="validite" name="validite" label="Validité" type="text" fullWidth variant="outlined" value={currentVisa.validite} onChange={handleChange} sx={{ mb: 2 }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSubmit}>{isEdit ? 'Modifier' : 'Ajouter'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirmez la suppression"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Êtes-vous sûr de vouloir supprimer ce visa ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)} color="primary">Annuler</Button>
          <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>Confirmer</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VisasCrud;
