import React, { useState } from 'react';
import { Grid, Container } from '@mui/material';
import Home from './Home';
import ListeBDC from './ListeBDC';
import DemandesDuBDC from './DemandesDuBDC';
import BonDeCommande from '../models/BonDeCommande';
import AdresseFacturation from './AdresseFacturation';

const FacturationScreen = () => {
  const [bonDeCommandeSelectionne, setBonDeCommandeSelectionne] = useState<BonDeCommande | null>(null);
  const [refreshKey, setRefreshKey] = useState(0); // clé pour forcer le rafraîchissement

  const refreshBonsDeCommande = () => {
    setRefreshKey(oldKey => oldKey + 1); // incrémente la clé pour forcer le re-render
  };

  return (
    <>
      <Home />
      <Container>
        <h2>Facturation</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            {/* Bon de Commande prend la moitié de l'espace */}
            <ListeBDC setBonDeCommandeSelectionne={setBonDeCommandeSelectionne} refreshKey={refreshKey} />
           
          </Grid>
          
          <Grid item xs={12} md={8}>
            {/* Adresse Facturation prend le dernier quart de l'espace */}
            {/* <AdresseFacturation /> */}
            <AdresseFacturation bonDeCommande={bonDeCommandeSelectionne} onRefresh={refreshBonsDeCommande} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FacturationScreen;
