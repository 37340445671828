import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, TextField, FormControlLabel, Checkbox, Box } from '@mui/material';

interface DemandeActionsProps {
  open: boolean;
  onClose: () => void;
  onValidated: () => void;
  selectedIds: number[];
  selectedEmail: string | null;
}

const DemandeActions: React.FC<DemandeActionsProps> = ({ open, onClose, onValidated, selectedIds, selectedEmail }) => {
  const [objetMail, setObjetMail] = useState(`Commandes Nº: ${selectedIds.join(', ')}`);
  const [alerte, setAlerte] = useState(false);
  const [envoyerMail, setEnvoyerMail] = useState(false);
  const [messageAppli, setMessageAppli] = useState('');
  const [messageMail, setMessageMail] = useState('');
  const formattedMessageMail = messageMail.replace(/\n/g, '<br>');
  const [localEmail, setLocalEmail] = useState(selectedEmail || '');

  useEffect(() => {
    if (!open) {
      setObjetMail('');
      setAlerte(false);
      setEnvoyerMail(false);
      setMessageAppli('');
      setMessageMail('');
    } else {
      setObjetMail(`Commandes Nº: ${selectedIds.join(', ')}`);
    }
  }, [open, selectedIds]);

  useEffect(() => {
    if (open) {
      setLocalEmail(selectedEmail || '');
    }
  }, [open, selectedEmail]);

  const handleValidation = async () => {
    const dataToSend = {
      ids: selectedIds,
      email: localEmail,
      objet: objetMail,
      alerte,
      envoyerMail,
      messageAppli,
      messageMail: formattedMessageMail,
    };

    const response = await fetch('https://nodejs-admin.visamonde.com/actionsDemande', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    });

    onClose();
    onValidated();
  };

  const fillProblemMessage = () => {
    const problemMessage = `
Bonjour,

Après examen de votre dossier, nous constatons que certaines informations ou pièces justificatives ne sont pas conformes. 
Merci de bien vouloir apporter les modifications nécessaires.

Pour toute question, vous pouvez nous contacter sur contact@visamonde.com

Cordialement,
L'équipe Visa Monde
    `;
    setMessageMail(problemMessage);
  };

  const fillInProgressMessage = () => {
    const inProgressMessage = `
Bonne nouvelle ! Votre dossier est complet et validé. 

Votre demande de visa sera traitée sous 24h.

Cordialement,
L'équipe Visa Monde
    `;
    setMessageMail(inProgressMessage);
  };

  const fillVisaLaterMessage = () => {
    const visaLaterMessage = `
Bonjour,

Nous vous informons que la demande de visa sera traitée plus tard. Nous vous tiendrons informé(e) une fois la procédure démarrée.

Pour toute question, n'hésitez pas à nous contacter.

Cordialement,
L'équipe Visa Monde
    `;
    setMessageMail(visaLaterMessage);
  };

  const fillAwaitingPaymentMessage = () => {
    const awaitingPaymentMessage = `
Bonjour,

Votre demande de visa est actuellement en attente de paiement. Veuillez effectuer le paiement dès que possible pour que nous puissions traiter votre dossier.

Merci de votre compréhension.

Cordialement,
L'équipe Visa Monde
    `;
    setMessageMail(awaitingPaymentMessage);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Actions pour les demandes sélectionnées</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="email-client"
          label="Email du client"
          type="email"
          fullWidth
          variant="outlined"
          value={localEmail || ''}
          onChange={(e) => setLocalEmail(e.target.value)} 
        />
        <TextField
          margin="dense"
          id="objet-mail"
          label="Objet mail"
          type="text"
          fullWidth
          variant="outlined"
          value={objetMail}
          onChange={(e) => setObjetMail(e.target.value)}
        />
        <FormControlLabel
          control={<Checkbox checked={alerte} onChange={(e) => setAlerte(e.target.checked)} />}
          label="Alerte"
        />
        <FormControlLabel
          control={<Checkbox checked={envoyerMail} onChange={(e) => setEnvoyerMail(e.target.checked)} />}
          label="Envoyer mail"
        />
        <TextField
          margin="dense"
          id="message-appli"
          label="Message Appli"
          type="text"
          fullWidth
          variant="outlined"
          value={messageAppli}
          onChange={(e) => setMessageAppli(e.target.value)}
        />
        <TextField
          margin="dense"
          id="message-mail"
          label="Message Mail"
          multiline
          rows={8}
          fullWidth
          variant="outlined"
          value={messageMail}
          onChange={(e) => setMessageMail(e.target.value)}
        />

        <Box display="flex" justifyContent="center" gap={2} mt={2}>
          <Button onClick={fillProblemMessage} variant="contained" color="primary">
            Mail Visa(s) Problème
          </Button>
          <Button onClick={fillInProgressMessage} variant="contained" color="primary">
            Mail Visa(s) en Cours
          </Button>
        </Box>

        {/* Ajout des nouveaux boutons */}
        <Box display="flex" justifyContent="center" gap={2} mt={2}>
          <Button onClick={fillVisaLaterMessage} variant="contained" color="primary">
            Mail Visa Plus Tard
          </Button>
          <Button onClick={fillAwaitingPaymentMessage} variant="contained" color="primary">
            Mail En Attente de Paiement
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={handleValidation} variant="contained">Valider</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemandeActions;
