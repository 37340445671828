import React, { useEffect, useState } from 'react';
import { TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { recupererDemandesBDC } from '../Services';

interface DemandesDuBDCProps {
  idBonDeCommande: number;
}

interface Demande {
  demande_id: number;
  libelle_visa: string;
  prix_consulat: number;
  prix_intervention: number;
  assurancevisa: string;
  nom_voyageur: string;
  prenom_voyageur: string;
}

const DemandesDuBDC: React.FC<DemandesDuBDCProps> = ({ idBonDeCommande }) => {
  const [demandes, setDemandes] = useState<Demande[]>([]); // Changer le type pour correspondre à l'objet demande

  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        const demandesDuBDC: Demande[] = await recupererDemandesBDC(idBonDeCommande);
        setDemandes(demandesDuBDC);
      } catch (error) {
        console.error('Erreur lors de la récupération des demandes pour le bon de commande:', error);
      }
    };

    fetchDemandes();
  }, [idBonDeCommande]);

  return (
    <Paper style={{ padding: 20 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID Demande</TableCell>
              <TableCell>Libellé Visa</TableCell>
              <TableCell>Prix Consulat</TableCell>
              <TableCell>Prix Intervention</TableCell>
              <TableCell>Assurance</TableCell>
              <TableCell>Nom Voyageur</TableCell>
              <TableCell>Prénom Voyageur</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {demandes.map((demande) => (
              <TableRow key={demande.demande_id}>
                <TableCell>{demande.demande_id}</TableCell>
                <TableCell>{demande.libelle_visa}</TableCell>
                <TableCell>{demande.prix_consulat}€</TableCell>
                <TableCell>{demande.prix_intervention}€</TableCell>
                <TableCell>{demande.assurancevisa === 'oui' ? 'Oui' : 'Non'}</TableCell>
                <TableCell>{demande.nom_voyageur}</TableCell>
                <TableCell>{demande.prenom_voyageur}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DemandesDuBDC;
