import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { recupereBDC } from '../Services';
import BonDeCommande from '../models/BonDeCommande';
import DemandesDuBDC from './DemandesDuBDC';

interface ListeBDCProps {
  setBonDeCommandeSelectionne: (bonDeCommande: BonDeCommande) => void;
  refreshKey: number; 
}


const ListeBDC: React.FC<ListeBDCProps> = ({ setBonDeCommandeSelectionne, refreshKey }) => {
  const [bonsDeCommande, setBonsDeCommande] = useState<BonDeCommande[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [factureFilter, setFactureFilter] = useState('Tous');


  const fetchBDC = async () => {
    try {
      const bdc = await recupereBDC();
      setBonsDeCommande(bdc);
    } catch (error) {
      console.error('Erreur lors de la récupération des bons de commande:', error);
    }
  };
  
  useEffect(() => {
    fetchBDC();
  }, [refreshKey]);
  

  const handleRowClick = (id: number) => {
    setSelectedId(id);
    const bonDeCommande = bonsDeCommande.find(bdc => bdc.id === id);
    if (bonDeCommande) {
      setBonDeCommandeSelectionne(bonDeCommande);
    }
  };

  const filteredBonsDeCommande = bonsDeCommande.filter((bdc) => {
    if (factureFilter === 'Tous') return true;
    return factureFilter === 'Oui' ? bdc.envoie_facture === 'oui' : bdc.envoie_facture === 'non';
  });
  

  return (
    <div>
     <FormControl variant="outlined" style={{ margin: '20px', width: '150px' }}>
  <InputLabel id="facture-filter-label">Filtrer par Facture</InputLabel>
  <Select
    labelId="facture-filter-label"
    id="facture-filter"
    value={factureFilter}
    onChange={(e) => setFactureFilter(e.target.value)}
    label="Filtrer par Facture"
  >
    <MenuItem value="Tous">Tous</MenuItem>
    <MenuItem value="Oui">Oui</MenuItem>
    <MenuItem value="Non">Non</MenuItem>
  </Select>
</FormControl>

      <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
           
            <TableCell>Total</TableCell>
            <TableCell>État</TableCell>
            <TableCell>Date paiement</TableCell>
            <TableCell>Envoi Facture</TableCell>
            
            {/* Add other headers as necessary */}
          </TableRow>
        </TableHead>
        <TableBody>
  {filteredBonsDeCommande.map((bdc) => (
    <TableRow
      key={bdc.id}
      onClick={() => handleRowClick(bdc.id)}
      hover
      style={{
        backgroundColor: selectedId === bdc.id ? '#add8e6' : 'inherit',
        color: selectedId === bdc.id ? 'white' : 'inherit',
      }}
    >
      <TableCell>{bdc.id}</TableCell>
      <TableCell>{bdc.total}</TableCell>
      <TableCell>{bdc.etat}</TableCell>
      <TableCell>{bdc.date_paiement ? new Date(bdc.date_paiement).toLocaleDateString() : ''}</TableCell>
      {/* Cellule de facture avec condition de lien */}
      <TableCell>
        {bdc.envoie_facture === 'oui' && bdc.urlfacture ? (
          <a href={bdc.urlfacture} target="_blank" rel="noopener noreferrer">
            Oui
          </a>
        ) : (
          bdc.envoie_facture
        )}
      </TableCell>
    </TableRow>
  ))}
</TableBody>


      </Table>
    </TableContainer>
     {/* {selectedId && <DemandesDuBDC idBonDeCommande={selectedId} />} */}
    </div>
  );
};

export default ListeBDC;
