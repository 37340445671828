import React, { useState, useCallback } from 'react';
import { createTheme, ThemeProvider, Container, Grid, Paper, Typography, Collapse, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { ListeDemandes } from './ListesDemandes';
import InfoVoyageur from './InfoVoyageur';
import Home from './Home';
import InfoFormulaire from './InfoFormulaire';

// Créez un thème personnalisé avec les réglages souhaités pour le container
const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          '@media (min-width: 1200px)': {
            maxWidth: '2400px',
          },
        },
      },
    },
  },
});

const DemandesScreen: React.FC = () => {
  const [selection, setSelection] = useState<{ idVoyageur: number | null; chemin: string | null }>({ idVoyageur: null, chemin: null });
  const [key, setKey] = useState<number>(0); // Clé pour forcer le rechargement du composant ListeDemandes
  const [isDemandesExpanded, setIsDemandesExpanded] = useState<boolean>(true); // État pour l'expansion de la liste des demandes
  const [isVoyageurExpanded, setIsVoyageurExpanded] = useState<boolean>(true); // État pour l'expansion de la section InfoVoyageur
  const [isFormulaireExpanded, setIsFormulaireExpanded] = useState<boolean>(true); // État pour l'expansion de la section InfoFormulaire

  const handleSelectionDemande = (idVoyageur: number, chemin: string) => {
    setSelection({ idVoyageur, chemin });
  };

  // Fonction pour rafraîchir la liste des demandes
  const rafraichirDemandes = useCallback(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  // Fonctions pour basculer l'expansion des sections
  const toggleDemandesExpansion = () => {
    setIsDemandesExpanded((prev) => !prev);
  };

  const toggleVoyageurExpansion = () => {
    setIsVoyageurExpanded((prev) => !prev);
  };

  const toggleFormulaireExpansion = () => {
    setIsFormulaireExpanded((prev) => !prev);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Home />
        <Container sx={{ pt: 2 }}>
          <Grid container spacing={3}>
            {/* Liste des demandes avec possibilité de collapse */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">Liste des Demandes</Typography>
                  <IconButton onClick={toggleDemandesExpansion}>
                    {isDemandesExpanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </div>
                <Collapse in={isDemandesExpanded}>
                  <ListeDemandes onSelectionDemande={handleSelectionDemande} key={key} />
                </Collapse>
              </Paper>
            </Grid>

            {/* Informations du Voyageur toujours visible et ouvert par défaut */}
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Informations du Voyageur</Typography>
                    <IconButton onClick={toggleVoyageurExpansion}>
                      {isVoyageurExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>
                  <Collapse in={isVoyageurExpanded}>
                    <InfoVoyageur
                      key={selection.idVoyageur ?? 0}
                      idVoyageur={selection.idVoyageur ?? 0}
                      chemin={selection.chemin ?? ""}
                      onValider={rafraichirDemandes}
                    />
                  </Collapse>
                </Paper>
              </Grid>

              {/* Informations du Formulaire toujours visible et ouvert par défaut */}
              <Grid item xs={12} md={6} lg={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Informations du Formulaire</Typography>
                    <IconButton onClick={toggleFormulaireExpansion}>
                      {isFormulaireExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>
                  <Collapse in={isFormulaireExpanded}>
                    <InfoFormulaire chemin={selection.chemin ?? ""} />
                  </Collapse>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default DemandesScreen;
