import React, { useEffect, useState } from 'react';
import { recupererContenuFormulaire, recupererPiecesJointes } from '../Services';
import { Paper, Typography, Box } from '@mui/material';

const InfoFormulaire: React.FC<{ chemin: string }> = ({ chemin }) => {
  const [contenu, setContenu] = useState<Record<string, any> | null>(null);
  const [piecesJointes, setPiecesJointes] = useState<string>('');

  useEffect(() => {
    if (chemin) {
      recupererContenuFormulaire(chemin)
        .then((donnees) => {
          setContenu(donnees);
        })
        .catch((erreur) => console.error(erreur));

      recupererPiecesJointes(chemin)
        .then((htmlLiens) => {
          setPiecesJointes(htmlLiens);
        })
        .catch((erreur) => console.error(erreur));
    }
  }, [chemin]);

  // Fonction pour sélectionner le texte de la valeur lors du survol
  const handleMouseOver = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const target = event.target as HTMLSpanElement;
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(target);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  // Filtrer les clés que vous ne souhaitez pas afficher
  const filteredContenu = contenu
    ? Object.entries(contenu).filter(
        ([cle]) => !['etatFormulaire', 'idUtilisateur', 'idVoyageur'].includes(cle)
      )
    : [];

  if (!contenu) return <p>Chargement des informations...</p>;

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      {/* Utiliser dangerouslySetInnerHTML pour afficher le HTML des pièces jointes */}
      {piecesJointes && <div dangerouslySetInnerHTML={{ __html: piecesJointes }} />}

      {filteredContenu.map(([cle, valeur], index) => (
        <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'text', userSelect: 'none' }}>
          <Typography component="span">{`${cle}: `}</Typography>
          {/* Appliquer onMouseOver uniquement sur la valeur */}
          <Typography component="span" onMouseOver={handleMouseOver} sx={{ userSelect: 'text' }}>
            {valeur}
          </Typography>
        </Box>
      ))}
    </Paper>
  );
};

export default InfoFormulaire;
