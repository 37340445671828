import React, { useRef, useState } from 'react';
import { Alert, Box, Button, Grid, Paper, Snackbar, Typography } from '@mui/material';
import BonDeCommande from '../models/BonDeCommande';
import axios from 'axios';
import DemandesDuBDC from './DemandesDuBDC';

interface AdresseFacturationProps {
    bonDeCommande: BonDeCommande | null;
    onRefresh: () => void; 
}

const AdresseFacturation: React.FC<AdresseFacturationProps> = ({ bonDeCommande, onRefresh }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState('');

    const handleUploadClick = () => {
        
        fileInputRef.current?.click();
        
    };

    if (!bonDeCommande) {
        return <Typography>Selectionnez un bon de commande pour voir les informations</Typography>;
    }

    const handleMouseOver = (event: React.MouseEvent) => {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(event.currentTarget);
        selection?.removeAllRanges();
        selection?.addRange(range);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files[0]) {
            const formData = new FormData();
            formData.append('file', files[0]);
            formData.append('bonDeCommandeId', bonDeCommande?.id.toString() || ''); 

            try {
                const response = await axios.post('https://nodejs-admin.visamonde.com/uploadFacture', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setUploadSuccess(true);
                setUploadError('');
                onRefresh();
                console.log('Fichier uploadé avec succès:', response.data);
            } catch (error) {
                setUploadSuccess(false);
                setUploadError('Erreur lors de l\'upload du fichier.');
                console.error('Erreur lors de l\'upload du fichier:', error);
            }
        }
    };

    const handleCloseSnackbar = () => {
        setUploadSuccess(false);
        setUploadError('');
    };

    
    return (
        <Paper style={{ padding: 20, marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
                Adresse de Facturation
            </Typography>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography>Nom : <span onMouseOver={handleMouseOver}>{bonDeCommande.nom}</span></Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>Adresse ligne 1 : <span onMouseOver={handleMouseOver}>{bonDeCommande.adresse_ligne1}</span></Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>Email : <span onMouseOver={handleMouseOver}>{bonDeCommande.email}</span></Typography>
            </Grid>
            {bonDeCommande.adresse_ligne2 && (
                <>
                <Grid item xs={6}>
                    <Typography>Adresse ligne 2 : <span onMouseOver={handleMouseOver}>{bonDeCommande.adresse_ligne2}</span></Typography>
                </Grid>
                <Grid item xs={6}>
                    {/* Espace vide pour alignement */}
                </Grid>
                </>
            )}
            <Grid item xs={6}>
                <Typography>Ville : <span onMouseOver={handleMouseOver}>{bonDeCommande.ville}</span></Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>Code Postal : <span onMouseOver={handleMouseOver}>{bonDeCommande.code_postal}</span></Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>Pays : <span onMouseOver={handleMouseOver}>{bonDeCommande.pays}</span></Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>Téléphone : <span onMouseOver={handleMouseOver}>{bonDeCommande.telephone}</span></Typography>
            </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" marginTop={2}>
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={handleUploadClick}
                >
                    Upload Facture
                </Button>
            </Box>
            <input
                type="file"
                accept=".pdf"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange} // Utilisez handleFileChange pour gérer le changement de fichier

            />

{/* Ajoutez ici le composant DemandesDuBDC pour afficher les demandes liées */}
<Box marginTop={4}>
      <Typography variant="h6" gutterBottom>
        Détails des Demandes Associées
      </Typography>
      {/* Passer l'ID du bon de commande sélectionné à DemandesDuBDC */}
      {bonDeCommande && <DemandesDuBDC idBonDeCommande={bonDeCommande.id} />}
    </Box>

             {uploadSuccess && <Snackbar open={uploadSuccess} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Fichier uploadé avec succès !
                </Alert>
            </Snackbar>}
            {uploadError && <Snackbar open={Boolean(uploadError)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {uploadError}
                </Alert>
            </Snackbar>}
        </Paper>
    );
};

export default AdresseFacturation;
